<template>
  <div class="open pad--all-md origin">
    <div class="origin">
      <h2>Open</h2>
      <button v-if="false" @click="$emit('close')" class="rst--button typ--i+1 abs--center-right">
        &times;
      </button>
    </div>
    <div class="flx ofst--top-md pad--left-sm pad--right-sm">
      <p class="subheading pad--left-xs typ--v-1 typ--bold">
        Name
      </p>
      <p class="subheading flx-end pad--right-xs typ--v-1 typ--bold">
        Created
      </p>
    </div>
    <ul class="open-list rst--list ovr--scroll-y pad--all-sm ofst--top-xs origin">
      <li
        class="open-item pad--all-sm flx"
        @click="is_selected = key"
        @dblclick="entries = key"
        :class="{ is_selected: is_selected === key, is_open: item.is_open }"
        :key="key"
        v-for="(item, key) in entries"
      >
        <p>
          {{ item.name }}
        </p>
        <p class="flx-end">
          {{ format(item.created, 'MM/dd/yyyy') }}
        </p>
      </li>
      <li v-if="is_empty && !loading" class="abs--center-center typ--center fll--parent-w">
        You don't currently have any saved flows
      </li>
    </ul>
    <Loader v-if="loading" class="abs--center-center" width="2rem" height="2rem" />
    <div class="flx abs--bottom-left abs--bottom-right pad--all-md">
      <button class="button--solid rst--button" @click="$emit('action', 'new')">New</button>
      <button
        @click="$emit('close')"
        class="button--outline flx-end rst--button"
      >
        Cancel
      </button>
      <button
        @click="entries = is_selected"
        class="button--solid ofst--left-sm rst--button"
        :disabled="disabled"
      >
        Open
      </button>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import Loader from '@/components/parts/loader.vue'
import {minimumTimeout} from '@/lib/minimumTimeout.js'

export default {
  components: {Loader},
  props: ["value"],
  data: () => ({
    is_selected: null,
    loading: true
  }),
  computed: {
    entries: {
      get() {
        const tabs = this.$store.state.flow.tabs
        const flows = this.value ? Object.keys(this.value).reduce((accumulator, uid) => {
          const node = this.value[uid]
          node.is_open = tabs.includes(uid);
          accumulator[uid] = node;
          return accumulator
        }, {}) : {};
        
        return flows;
      },
      set(val) {
        this.$emit("select", val);
      },
    },
    is_empty() {
      return !this.entries || Object.keys(this.entries).length == 0;
    },
    disabled () {
      return !this.is_selected;
    }
  },
  mounted () {
    const timeout = minimumTimeout(500);
    timeout().then(() => {
      this.loading = false;
    })
  },
  methods: {
    format,
  },
};
</script>

<style lang="scss" scoped>
.open {
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  width: calc(100% - 4rem);
  min-width: 24rem;
  max-width: 36rem;
  height: calc(100% - 4rem);
  max-height: 36rem;
  min-height: 10rem;
  padding-bottom: 5rem;
}

.open-list {
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 .1rem rgba(0, 0, 0, 0.4) inset;
  height: calc(100% - 4rem);
  border-radius: .25rem;
}

.open-item {
  cursor: default;
  border-radius: .25rem;

  &:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.open-item.is_selected {
  background: rgba(0, 0, 0, 0.3);
}

.open-item.is_open {
  pointer-events: none;
  opacity: 0.2;
}
</style>
