<template>
  <div class="properties">
    <ul class="rst--list">
      <li :key="uid" v-for="(node, uid) in selected">
        {{node.name}}
        <ul class="rst--list">
          <li :key="key" v-for="(data, key) in node.data">
            <input type="text" :value="key" />
            <input type="text" :valye="data" />
          </li>
          <li>
            <button class="rst-button" @click="handleAddData(uid)">Add</button>
          </li>
        </ul>
      </li>
    </ul>
    <div class="properties-enter pad--all-sm origin">

      <div class="origin fll--parent">
        <div ref="enter" ></div>
      </div>
    </div>
  </div>
</template>

<script>
// import CodeFlask from 'codeflask';
import cloneDeep from 'lodash/cloneDeep';
export default {
  computed: {
    selected () {
      const selected = this.$store.state.selected;
      const nodes = this.$store.state.flow.nodes;
      return cloneDeep(selected.map(({uid}) => nodes[uid]));
    }
  },
  mounted () {
    // this.flask = new CodeFlask(this.$refs.enter, { language: 'js'});
  },
  methods: {
    handleAddData (uid) {
      this.selected[uid].data['key'] = 'data'
    }
  }
}
</script>

<style lang="scss" scoped>
.properties {
  &-enter {
    height: 20rem;
  }
}
</style>