<template>
  <div class="menu origin pad--right-md">
    <ul class="rst--list flx gtr--xs pad--left-md ">
      <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('file')"
          @click="handleClick('file')"
          :class="{ is_active: isOver === 'file' && selecting }"
          :disabled="disabled('file')"
        >
          File
        </button>
      </li>
      <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('edit')"
          @click="handleClick('edit')"
          :class="{ is_active: isOver === 'edit' && selecting }"
          :disabled="disabled('edit')"
        >
          Edit
        </button>
      </li>
      <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('insert')"
          @click="handleClick('insert')"
          :class="{ is_active: isOver === 'insert' && selecting }"
          :disabled="disabled('insert')"
        >
          Insert
        </button>
        </li>
        <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('pairing')"
          @click="handleClick('pairing')"
          :class="{ is_active: isOver === 'pairing' && selecting }"
          :disabled="disabled('pairing')"
        >
          Pairing
        </button>
        </li>
        <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('account')"
          @click="handleClick('account')"
          :class="{ is_active: isOver === 'account' && selecting }"
          :disabled="disabled('account')"
        >
          Account
        </button>
        </li>
        <li>
        <button
          class="menu-button rst--button"
          @mouseenter="handleMouseEnter('help')"
          @click="handleClick('help')"
          :class="{ is_active: isOver === 'help' && selecting }"
          :disabled="disabled('help')"
        >
          Help
        </button>
        </li>
    </ul>
    <ul v-show="isOver === 'file' && selecting" class="menu-drop menu-drop--file abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('new')" :disabled="disabled('new')">New flow&hellip; <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⌃N</span></button></li>
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('open')" :disabled="disabled('open')">Open&hellip; <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⌘O</span></button></li>
      <li>
        <label class="menu-drop-button rst--button origin" :disabled="disabled('open')">Open from desktop&hellip; <input @change="handleFileChange" class="abs--center-right" style="opacity: 0;" type="file" /></label>
      </li>
      <li>
        <hr class="menu-drop-hr ofst--top-xs ofst--bottom-xs" />
      </li>
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('save_as')" :disabled="disabled('save_as')">Save As&hellip; <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⇧⌘S</span></button></li>
      <li><button class="menu-drop-button rst--button" @click="handleAction('save_to_desktop')" :disabled="disabled('save_to_desktop')">Save to desktop...</button></li>
      <li>
        <hr class="menu-drop-hr ofst--top-xs ofst--bottom-xs" />
      </li>
      <li><button class="menu-drop-button rst--button" @click="handleAction('share_secure_link')" :disabled="disabled('share_secure_link')">Share secure link...</button></li>
      <li><button class="menu-drop-button rst--button" @click="handleAction('share_public_link')" :disabled="disabled('share_public_link')">Share public link...</button></li>
      <li v-if="false"><button class="menu-drop-button rst--button" @click="handleAction('create_survey')" :disabled="disabled('create_survey')">Create survey... (coming soon)</button></li>
    </ul>


    <ul v-show="isOver === 'edit' && selecting" class="menu-drop menu-drop--edit abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('undo')" :disabled="disabled('Undo') || !hasHistory">Undo <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⌘Z</span></button></li>
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('redo')" :disabled="disabled('Redo') || !hasFuture">Redo <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⇧⌘Z</span></button></li>
    </ul>

    <ul v-show="isOver === 'insert' && selecting" class="menu-drop menu-drop--insert abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button origin" @click="handleAction('insert_node')" :disabled="disabled('insert_node')">Node <span class="menu-drop-button-shortcut abs--center-right ofst--right-sm">⌘I</span></button></li>
    </ul>

    <ul v-show="isOver === 'pairing' && selecting" class="menu-drop menu-drop--pairing abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button" @click="handleAction('pair_start')" :disabled="disabled('pair_start')">Start...</button></li>
      <li><button class="menu-drop-button rst--button" @click="handleAction('pair_join')" :disabled="disabled('pair_join')">Join...</button></li>
    </ul>

    <ul v-show="isOver === 'account' && selecting" class="menu-drop menu-drop--account abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button" @click="handleAction('settings')" :disabled="disabled('settings')">Settings</button></li>
      <li><button @click="signOut" class="menu-drop-button rst--button">Sign Out</button></li>
    </ul>
    <ul v-show="isOver === 'help' && selecting" class="menu-drop menu-drop--help abs--bottom-left rst--list pad--top-sm pad--bottom-sm pad--all-md">
      <li><button class="menu-drop-button rst--button" @click="handleAction('contact_support')" :disabled="disabled('contact_support')">Contact support&hellip;</button></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    disabledList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isOver: "",
    selecting: false,
  }),
  mounted () {
    document.addEventListener('click', this.clickOutside)
  },
  unmounted () {
    document.removeEventListener('click', this.clickOutside)
  },
  computed: {
    hasHistory () {
      return this.$store.getters['flow/hasHistory']
    },
    hasFuture () {
      return this.$store.getters['flow/hasFuture']
    }
  },
  methods: {
    clickOutside ({target}) {
      if (!this.$el.contains(target)){
        this.isOver = null;
        this.selecting = false;
      }
    },
    disabled (name) {
      return this.disabledList.includes(name)
    },
    handleMouseEnter(name) {
      this.isOver = name;
    },
    handleClick (name) {
      this.selecting = !this.selecting;
      this.isOver = name
    },
    handleAction (action, params) {
      this.$emit('action', action, params);
      this.isOver = null;
      this.selecting = false;
    },
    handleFileChange (e) {
      var reader = new FileReader();
      reader.onload = e => {
        const nodes = JSON.parse(e.target.result);
        this.handleAction('upload', {nodes});
      };
      reader.readAsText(e.target.files[0]);
    },
    signOut () {
      this.$store.dispatch('auth/signOut').then(() => {
        this.$router.push({name: 'Signin'})
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.menu-button {
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;

  &:disabled {
    opacity: 0.4;
  }
}

.menu-button.is_active {
  background-color: rgba(0,0,0, 0.1);
}

.menu-drop {
  background-color: #ffffff;
  border-radius: .5rem;
  line-height: 1.5rem;
  border: solid thin #eee;
  min-width: 16rem;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
}

.menu-drop-button {
  display: block;
  margin: 0 -0.5rem;
  width: auto;
  width: calc(100% + 1rem);
  padding: .5rem;
  border-radius: .5rem;

  &-shortcut {
    color: #999;
  }

  &:disabled {
    opacity: 0.4;
  }
}

.menu-drop-button:hover {
  background-color: rgba(0,0,0, 0.08);

  > .menu-drop-button-shortcut {
    color: rgba(0,0,0, 1);
  }
}

.menu-drop--file {
  transform: translate(1rem, 100%);
}

.menu-drop--edit {
  transform: translate(4.25rem, 100%);
}


.menu-drop--insert {
  transform: translate(7.75rem, 100%);
}

.menu-drop--pairing {
  transform: translate(11.75rem, 100%);
}

.menu-drop--account {
  transform: translate(16.75rem, 100%);
}

.menu-drop--help {
  transform: translate(22.75rem, 100%);
}

.menu-drop-hr {
  border: 0;
  border-bottom: solid thin #ccc;
}
</style>
