<template>
  <div class="name pad--all-lg" >
    <div class="origin">
      <h2 class="name-heading ovr--ellipsis">Save '{{curretFlowName}}'' as</h2>
      <button v-if="false" @click="$emit('close')" class="rst--button typ--i+1 abs--center-right">
        &times;
      </button>
    </div>
    <form @submit.stop.prevent="handleSubmit" class="ofst--top-md">
      <div class="flx">
        <input-text v-model="name" @ready="handleInputReady" class="flx-fill">Name</input-text>
      </div>
      <ul class="rst--list flx ofst--top-md">
        <li class="flx-end">
          <button type="button" class="button--outline rst--button" @click.stop.prevent="$emit('close')">Cancel</button>
        </li>
        <li class="ofst--left-sm">
          <button type="submit" class="button--solid rst--button" :disabled="createDisabled">Save</button>
        </li>
      </ul>
    </form>
    <p>
      {{success || error}}
    </p>
  </div>
</template>

<script>
import InputText from './parts/input-text.vue';
export default {
  components: { InputText },
  props: {
    curretFlowName: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    InputTextsuccess: null,
    error: null,
    name: '',
    success: null
  }),
  computed: {
    createDisabled () {
      return !this.name && this.name.length == 0
    }
  },
  mounted () {

  },
  methods: {
    handleInputReady: ({focus}) => focus(),
    handleSubmit () {
      let res = (success) => {
        this.success = success
      };
      let rej = (error) => {
        this.error = error
      };
      this.$emit('submit', {name: this.name}, res, rej)
    }
  }
}
</script>

<style lang="scss" scoped>
.name {
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
  min-width: 20rem;
  width: calc(100% - 4rem);
  max-width: 28rem;

  &-heading {
    max-width: calc(100% - 2rem);
  }
}
</style>