<template>
  <div class="link pad--all-lg" >
    <div class="origin">
      <h2>Share link</h2>
      <p class="abs--center-right">
        {{success || error}}
      </p>
      <button @click="$emit('close')" class="rst--button typ--i+1 abs--center-right">
        &times;
      </button>
    </div>
    <div class="flx ofst--top-md">
      <input-text :modelValue="shareLink" :focus="true" class="flx-fill">Link</input-text>
      <button @click="copy" type="button" class="link-createButton button--solid rst--button origin">
        <span v-if="!loading">{{copyText}}</span>
        <Loader v-else width="2rem" height="2rem" class="abs--center-center" />
      </button>
    </div>
  </div>
</template>

<script>
import InputText from './parts/input-text.vue';
import Loader from '@/components/parts/loader.vue'
import copy from 'copy-to-clipboard';

export default {
  components: { InputText, Loader },
  props: {
    shareLink: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    copyText: 'Copy',
    error: null,
    success: null,
    loading: false
  }),
  methods: {
    copy () {
      copy(this.shareLink);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.copyText = 'Copied';
        setTimeout(() => {
          this.copyText = 'Copy'
        }, 4000);
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
  min-width: 20rem;
  width: calc(100% - 4rem);
  max-width: 28rem;

  &-createButton {
    min-width: 100px;
  }
}
</style>