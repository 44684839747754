<template>
  <div class="settings pad--all-lg">
    <div class="origin">
      <h2>Settings</h2>
      <button v-if="false" @click="$emit('close')" class="rst--button typ--i+1 abs--center-right">
        &times;
      </button>
    </div>
    <ul class="rst--list ofst--top-md">
      <li class="row--lg flx">
        <input-text v-model="displayName" type="text" class="flx-fill">Display Name</input-text>
      </li>
      <li class="settings-unavailable row--lg flx">
        <input-text v-model="email" type="email" class="flx-fill">Email</input-text>
      </li>
    </ul>

    <div class="settings-notification fll--parent-w origin">
      <p v-if="error || success" class="origin typ--center abs--top-center ofst--top-md fll--parent-w">
        {{error}} {{success}}
        <button class="rst--button ofst--left-sm" @click="clearNotification()">&times;</button>
      </p>
    </div>
    <div class="flx gtr--md">
      <h3>Account Type</h3>
      <div class="flx-fill">
        <hr />
      </div>
    </div>
    
    <ul v-if="false" class="rst--list flx ofst--top-md">
      <li class="@base--1-2">
        <h4 class="subheading typ--v-1">Getting started</h4>
        <ul class="ofst--top-sm rst--list pad--left-sm">
          <li class="row--sm">&check; 10 Projects</li>
          <li class="row--sm">&check; 20 Builds per month</li>
          <li class="row--sm">&check; Public share Links</li>
          <li class="ofst--top-md">
            <button disabled>Selected</button>
          </li>
        </ul>
      </li>
      <li class="settings-unavailable @base--1-2">
        <h4 class="typ--v-1">
          <span class="subheading">Gone Pro</span> (coming soon)
        </h4>
        <p class="ofst--top-sm"><em>Everything in 'Getting Started' plus...</em></p>
        <ul class="rst--list ofst--top-sm pad--left-sm">
          <li class="row--sm">&check; Realtime collaboration</li>
          <li class="row--sm">&check; Unlimited projects</li>
          <li class="row--sm">&check; Unlimited builds</li>
          <li class="row--sm">&check; Private share links</li>
          <li class="ofst--top-md">
            <button>Select</button>
          </li>
        </ul>
      </li>
    </ul>
    
    <div class="flx ofst--top-lg">
      <button class="button--outline flx-end rst--button" @click="$emit('close')">Done</button>
      <button class="settings-save button--solid ofst--left-sm rst--button" :disabled="disabled" @click="handleUpdate">
        <Loader v-if="loading" width="2rem" height="2rem" class="typ--i+1" />
        <span v-if="!loading" >
          {{ disabled ? 'Saved' : 'Save'}}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/parts/input-text.vue';
import Loader from '@/components/parts/loader.vue'
import {minimumTimeout} from '@/lib/minimumTimeout.js'
import { ref } from 'vue'

export default {
  components: {InputText, Loader},
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    }
  },
  setup (props) {
    const displayName = ref(props.modelValue.displayName);
    const email = ref(props.modelValue.email);
    const oldDisplayName = ref(displayName.value)
    const oldEmail = ref(email.value)

    return {
      displayName,
      email,
      oldDisplayName,
      oldEmail
    }
  },
  data () {
    return {
      error: null,
      success: null,
      loading: false
    }
  },
  computed: {
    disabled () {
      return this.displayName === this.oldDisplayName && this.email === this.oldEmail
    },
    userProfile () {
      return this.modelValue;
    },
  },
  methods: {
    clearNotification () {
      this.error = this.success = null;
    },
    handleUpdate () {
      if (this.displayName !== this.oldDisplayName) {
        this.loading = true;
        const timeout = minimumTimeout(1000);

        let res = (success) => {
          this.success = success;
          this.oldDisplayName = this.displayName;

          timeout().then(() => {
            this.loading = false;
          })
        };
        let rej = (error) => {
          this.error = error;
          timeout().then(() => {
            this.loading = false
          })
        };
        this.$emit('updateProfile', {displayName: this.displayName}, res, rej);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
  min-width: 32rem;
  width: calc(100% - 4rem);
  max-width: 48rem;

  &-notification {
    height: 4rem;
  }

  &-save {
    min-width: 7rem;
  }

  &-unavailable {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>