<template>
  <div class="tabs">
    <ul class="rst--list flx">
      <li :key="uid" v-for="uid in tabs" class="tabs-tab origin" >
        <button class="tabs-tab-button rst--button typ--v-1" :class="{'is_selected': uid === flowUid}" @click="$emit('open', uid)">
          {{allFlows[uid].name}}
          <small v-if="readOnly">(Read only)</small>
        </button>
        <button v-show="tabs.length > 1" @click="closeTab(uid)" class="tabs-tab-close rst--button abs--center-right ofst--right-sm">&times;</button>
      </li>
      <li class="tabs-tabFill flx-fill origin">
        <button v-if="!demo" class="tabs-tabFill-new rst--button abs--center-left typ--iii pad--left-sm pad--right-sm fll--parent-h ofst--left-xs" @click="demo ? $emit('action', 'new') : $emit('action', 'open')">+</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tabs: {
      get () {
        return this.modelValue
      }
    },
    demo () {
      return this.$store.state.flow.demo
    },
    readOnly () {
      return this.$store.getters['flow/readOnly']
    },
    allFlows () {
      return this.$store.state.flow.allFlows
    },
    flowUid () {
      return this.$store.state.flow.flowUid;
    },
    userUid () {
      return this.$store.state.auth.uid
    }
  },
  methods: {
    closeTab(uid) {
      this.$store.commit('flow/removeRecentFlow', uid)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  // background-color: rgba(255,255,255,0.9);

  &-tab {
    &-button {
      padding: .25rem 1.5rem .25rem 1rem;
      border-radius: .25rem .25rem 0 0;
      border: solid thin transparent;
      border-bottom-color: gray;

      &.is_selected {
        border-color: gray;
        border-bottom-color: transparent;
        color: #777;
      }
    }

    &-close {
      color: #777;
      &:hover {
        color: #000;
      }
    }
  }

  &-tab + &-tab {
    margin-left: -1px;
  }

  &-tabFill {
    border-bottom: solid thin gray;

    &-new {
      border-radius: .25rem;
      transition: background-color .25s ease;

      &:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}
</style>