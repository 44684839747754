<template>
  <div class="new pad--all-lg" >
    <div class="origin">
      <h2>New flow</h2>
      <p class="abs--center-right">
        {{success || error}}
      </p>
      <button v-if="false" @click="$emit('close')" class="rst--button typ--i+1 abs--center-right">
        &times;
      </button>
    </div>
    <form @submit.stop.prevent="handleSubmit" class="ofst--top-md">
      <div class="flx">
        <input-text v-model="name" @ready="handleInputReady" class="flx-fill">Name</input-text>
      </div>
      <ul class="rst--list flx ofst--top-md">
        <li class="flx-end">
          <button type="button" class="button--outline rst--button" @click.stop.prevent="$emit('close')">Cancel</button>
        </li>
        <li class="ofst--left-sm">
          <button type="submit" class="new-createButton button--solid rst--button origin" :disabled="createDisabled">
            <span v-if="!loading">Create</span>
            <Loader v-else width="2rem" height="2rem" class="abs--center-center" />
          </button>
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import InputText from './parts/input-text.vue';
import Loader from '@/components/parts/loader.vue'
import {minimumTimeout} from '@/lib/minimumTimeout.js'

export default {
  components: { InputText, Loader },
  data: () => ({
    InputTextsuccess: null,
    error: null,
    name: '',
    success: null,
    loading: false
  }),
  computed: {
    createDisabled () {
      return !this.name && this.name.length == 0
    }
  },
  mounted () {

  },
  methods: {
    handleInputReady: ({focus}) => focus(), 
    handleSubmit () {
      this.loading = true;
      const timeout = minimumTimeout(1000);

      let res = (success) => {
        this.success = success
        timeout().then(() => {
          this.loading = false
          this.$emit('close')
        })
      };
      let rej = (error) => {
        this.error = error;
        timeout().then(() => {
          this.loading = false
        })
      };
      this.$emit('submit', {name: this.name}, res, rej)
    }
  }
}
</script>

<style lang="scss" scoped>
.new {
  border-radius: .5rem;
  background-color: #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0, 0.5);
  min-width: 20rem;
  width: calc(100% - 4rem);
  max-width: 28rem;

  &-createButton {
    min-width: 100px;
  }
}
</style>