/**
 * Detect object collision
 * @param {number} a first object position, width and height
 * @param {number} b second object position, width and height
 * @returns {Boolean}
 */

export function isCollide(a, b) {
  return !(
    a.y + a.height < b.y ||
    a.y > b.y + b.height ||
    a.x + a.width < b.x ||
    a.x > b.x + b.width
  );
}

/**
 * Detect object collision
 * @param {selectedRects} a first object position, width and height
 * @param {boundaries} b second object position, width and height
 * @returns {Array}
 */

export function getBoundaries(selectedRects) {
  return selectedRects && selectedRects.length > 0 ? selectedRects.reduce((boundaries, { top, left, bottom, right }, i) => {
        if (i === 0) {
          return { top, left, bottom, right };
        }
        return {
          top: boundaries.top > top ? top : boundaries.top,
          left: boundaries.left > left ? left : boundaries.left,
          bottom: boundaries.bottom < bottom ? bottom : boundaries.bottom,
          right: boundaries.right < right ? right : boundaries.right,
        };
      }, {})
    : {};
}

/**
 * Detect object collision
 * @param {selected} a first object position, width and height
 * @returns {Array}
 */

export function getRects(selected) {
  return selected && selected.length ? selected.map((node) => {
    let rect = node.getBoundingClientRect().toJSON();
    rect.uid = node.id;
    return rect
  }) : [];
}

export function getHitBoxes(selected) {
  return selected && selected.length ? selected.map((node) => {
    let rect = node.getBoundingClientRect().toJSON();
    let uid = node.id;
    return {uid, ...rect}
  }) : [];
}

export function getSelection(selectable, selectableRects, selectionArea) {
  let collisions = selectableRects.map((rect) => isCollide(selectionArea, rect));
  return selectable ? selectable.filter((node, i) => collisions[i]) : [];
}

export function getCollisions(hitBoxes, selectionArea) {
  return hitBoxes.filter((hitBox) => isCollide(selectionArea, hitBox));
}

export function getSelectable(selectionLayer, filter) {
  let selectable = selectionLayer && selectionLayer.children.length ? Array.from(selectionLayer.children) : [];
  return selectable.length && filter ? selectable.filter(filter) : selectable;
}


export function getTemplates(nodes) {
  return nodes.map(node => {
    let rect = node.getBoundingClientRect().toJSON();
    let uid = node.id;
    let parentRect = node.parentNode.getBoundingClientRect().toJSON();

    return {
      parentRect,
      uid,
      template: {
        style: {
          left: rect.left - parentRect.left,
          top: rect.top - parentRect.top,
        },
      },
    }
  })
}

export function isOver(rect, x, y) {
  return x > rect.x && x < rect.x + rect.width && y > rect.y && y < rect.y + rect.height
}