import { h } from 'vue';
import {getSelectable, getHitBoxes, getCollisions, isOver} from './lib.js';
import debounce from 'lodash/debounce';

export default {
  props: {
    filter: {
      type: Function,
      default: null
    },
    modelValue: {
        type: Array,
        default: '',
    },
    selecting: {
      type: Boolean,
      defalt: false
    },
    selectionArea: {
      type: Object,
      default: () => ({})
    },
    selectionLayer: {
      type: HTMLElement,
      default: null
    },
    hitBoxes: {
      type: Array,
      default: () => []
    },
    zoom: {
      type: Number,
      default: 1
    },
  },
  emits: ["mousedown", "mousemove", "mouseup", "mouseover",  "scroll", "update:modelValue", "update:selectionArea", 'update:hitBoxes'],
  data() {
    return {
      over: null,
      selectable: [],
      scrollPosition: {
        top: 0,
        left: 0
      },
      mousePosition: {
        start: { x: 0, y: 0 },
        current: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
      },     
    };
  },
  computed: {
    selected: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    selectedUids () {
      return this.selected.map(({uid}) => uid)
    },
    listeners () {
      return {
        ...this.listeners,
        onmousedown: this.handleMouseDown,
        onmousemove: this.handleMouseMove,
        onmouseup: this.handleMouseUp,
        onscroll: this.handleScroll,
      }
    },
  },
  watch: {
    mousePosition: {
      deep: true,
      handler (mousePosition) {
        let { start, current } = mousePosition;
      
        this.$emit('update:selectionArea', {
          y: start.y < current.y ? start.y : current.y,
          x: start.x < current.x ? start.x : current.x,
          width: start.x ? Math.abs(current.x - start.x) : 0,
          height: start.y ? Math.abs(current.y - start.y) : 0,
        })  
      }
    },
    selectionLayer (el) {
      if (el) {
        this.observer = new MutationObserver(this.onMutate);
        this.observer.observe(this.selectionLayer, { attributes: false, childList: true, subtree: false });
      } else {
        this.observer.disconnect()
      }
    }
  },
  unmounted() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    clearMouseposition() {
      this.mousePosition = {
        start: { x: null, y: null },
        current: { x: null, y: null },
        end: { x: null, y: null },
      };
    },
    onMutate () {
      this.updateHitBoxes();
    },
    updateHitBoxes () {
      this.selectable = this.selectionLayer ? getSelectable(this.selectionLayer, this.filter) : []
      const hitBoxes = this.selectable.length ? getHitBoxes(this.selectable) : [];
      const selected = hitBoxes.filter(({uid}) => this.selectedUids.includes(uid));
      this.selected = selected;
      this.$emit('update:hitBoxes', hitBoxes);
    }, 
    handleMouseDown(e) {
      e.preventDefault();
      let {x, y, shiftKey} = e;
      this.mousePosition.start = { x, y };
      this.mousePosition.current = {x, y};
      this.updateHitBoxes();

      if (this.selectedUids.includes(this.over)) {
        return this.$emit('mousedown', e, true);
      }

      if (this.over) {
        if (shiftKey) {
          const selected = this.selected.slice();
          if (this.selectedUids.includes(this.over)) {
            const index = this.selectedUids.indexOf(this.over);
            selected.splice(index, 1);
            this.selected = selected
          } else {
            const hitBox = this.hitBoxes.filter(({uid}) => uid === this.over).shift();
            selected.push(hitBox);
            this.selected = selected
          }
        } else {
          const hitBox = this.hitBoxes.filter(({uid}) => uid === this.over).shift();
          this.selected = [hitBox];
          return this.$emit('mousedown', e, true);
        }
      } else {
        this.selected = [];
      }

      this.$emit('mousedown', e);
    },
    handleMouseMove(e) {
      e.preventDefault();
      let {x, y } = e
      this.mousePosition.current = { x, y };

      if (!this.selecting) {
        const node = this.hitBoxes.filter((hitBox) => isOver(hitBox, x, y)).pop();
        const uid = node && 'uid' in node ? node.uid : null;

        if (this.over !== uid) {
          this.over = uid;
          this.$emit('mouseover', node)
        }
        return this.$emit('mousemove', e);
      }

      let {selected, selectionArea, hitBoxes } = this;
      const collisions = getCollisions(hitBoxes, selectionArea);
      const collisionUids = collisions.map(({uid}) => uid);
      const selectedUids = selected.map(({uid}) => uid);
      const toAdd = collisionUids.filter((uid) => !selectedUids.includes(uid));
      const toRemove = selectedUids.filter((uid) => !collisionUids.includes(uid));

      if (toAdd.length || toRemove.length) {
        this.selected = collisions;
      }
      
      this.$emit('mousemove', e);
    },
    handleMouseUp(e) {
      e.preventDefault();
      let {x, y } = e
      this.mousePosition.end = { x, y };
      this.clearMouseposition();
      this.$emit('mouseup', e);
      this.updateHitBoxes();
    },
    handleScroll: debounce(function ({target}) {
      this.updateHitBoxes();
      this.$emit('scroll', {
        top: target.scrollTop,
        left: target.scrollLeft
      });
    }),
  },
  render() {
    return h('div', {
      ...this.listeners
    }, this.$slots.default());
  },
};
