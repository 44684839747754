<template>
  <Artboard
    v-if="readOnly" class="canvas"
    :flow="flow"
    @ready="handleReady"
  />
  <drag-select
    v-else
    ref="dragSelect"
    class="canvas"
    v-model="selected"
    v-model:hitBoxes="hitBoxes"
    v-model:selectionArea="selectionArea"
    :selectionLayer="selectionLayer"
    :selecting="selecting"
    :filter="selectableFilter"
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
    @mouseover="handleMouseOver"
    @dblclick="handleDblClick"
    @scroll="handleScroll"
  >
    <Artboard
      ref="artboard"
      :flow="flow"
      @ready="handleReady"
    />
    <div v-show="selecting" :style="selectorStyle" class="canvas-selector" />
    <div
      v-show="!dragging && !editing"
      :key="key"
      v-for="(style, key) in selectedRectsStyles"
      :style="style"
      class="canvas-outline"
    />
    <div
      v-show="selected.length > 1 && !dragging && !editing"
      :style="handlesStyle"
      class="canvas-outline"
    />
  </drag-select>
</template>

<script>
import Artboard from "./Artboard";
import DragSelect from "./DragSelect";
import {getBoundaries} from '../lib/hit-box';
import hotkeys from 'hotkeys-js';

export default {
  components: { Artboard, DragSelect },
  data: () => ({
    selectableFilter: (node) => node.tagName !== "svg"
  }),
  setup() {},
  computed: {
    selected: {
      get () {
        return this.$store.state.selected.slice();
      },
      set (selected) {
        this.$store.commit('setSelected', selected)
      }
    },
    hitBoxes: {
      get () {
        return this.$store.state.flow.nodeRects
      },
      set (hitBoxes) {
        this.$store.commit('flow/setNodeRects', hitBoxes)
      }
    },
    selectionArea: {
      get () {
        return this.$store.state.selectionArea
      },
      set (selectionArea) {
        this.$store.commit('setSelectionArea', selectionArea)
      }
    },
    offset: {
      get () {
        return this.$store.state.offset
      },
      set (offset) {
        this.$store.commit('setOffset', offset)
      }
    },
    scrollPosition () {
      return this.$store.state.scroll
    },
    selectorStyle () {
      return this.selecting ? {
        top: this.selectionArea.y + 'px',
        left: this.selectionArea.x + 'px',
        width: this.selectionArea.width + 'px',
        height: this.selectionArea.height + 'px',
      } : null;
    },
    handlesStyle () {
      const handlesBoundaries = getBoundaries(this.selected);
      return {
        top: handlesBoundaries.top + this.scrollPosition.top - this.offset.top + "px",
        left: handlesBoundaries.left + this.scrollPosition.left - this.offset.left + "px",
        width: handlesBoundaries.right - handlesBoundaries.left + "px",
        height: handlesBoundaries.bottom - handlesBoundaries.top  + "px",
      };
    },
    selectedRectsStyles () {
      return this.selected ? this.selected.map((rect) => {
        let { top, left, width, height } = rect;
        return {
          top: top - this.offset.top + this.scrollPosition.top +  "px",
          left: left - this.offset.left + this.scrollPosition.left + "px",
          width: width + "px",
          height: height + "px",
        };
      }) : null
    },
    flow () {
      return this.$store.state.flow.nodes
    },
    readOnly () {
      return this.$store.getters['flow/readOnly']
    },
    participants () {
      return this.$store.state.room.participants;
    },
    cursorState () {
      return this.$store.state.cursorState;
    },
    selectionLayer () {
      return this.$store.state.selectionLayer;
    },
    editing () {
      return !!this.$store.state.flow.editingNodeUid
    },
    dragging () {
      return this.$store.state.cursorState === 'dragging'
    },
    selecting () {
      return this.$store.state.cursorState === 'selecting'
    }
  },
  mounted () {
    hotkeys('Backspace, Delete, Del', (e) => {
      e.preventDefault();
      this.$store.dispatch('flow/deleteNodes');
      return false;
    });

    hotkeys('ctrl+z, command+z', (e) => {
      e.preventDefault();
      this.$store.dispatch('flow/undo');
      return false;
    });

    hotkeys('ctrl+shift+z, command+shift+z', (e) => {
      e.preventDefault();
      this.$store.dispatch('flow/redo');
      return false;
    });

    hotkeys('ctrl+i, command+i', (e) => {
      e.preventDefault();
      var y = window.innerHeight/2 - 75;
      var x = window.innerWidth/2 - 40;
      this.$store.dispatch('flow/createNode', {x, y});
      return false;
    });

    hotkeys('ctrl+n, command+n', (e) => {
      e.preventDefault();
      this.$emit('action', 'new');
      return false;
    });

    hotkeys('ctrl+o, command+o', (e) => {
      e.preventDefault();
      this.$emit('action', 'open');
      return false;
    });

    hotkeys('ctrl+shift+s, command+shift+s', (e) => {
      e.preventDefault();
      this.$emit('action', 'save_as');
      return false;
    });
  },
  methods: {
    handleReady ($el) {
      this.$store.dispatch('flow/init').then(() => {
        const {top, left} = $el.getBoundingClientRect().toJSON();
        this.$store.commit('setOffset', {top, left});
        this.$store.commit('flow/setRootEl', $el);
        this.$store.commit('setSelectionLayer', $el);
      });
    },
    handleMouseDown({x, y}, dragging) {
      this.$store.dispatch('mouseDown', {x, y});
      if (dragging) {
        this.$store.commit('flow/pushHistory');
        this.$store.commit('setCursorState', 'drag_possible')
      } else {
        this.$store.commit('setCursorState', 'selecting')
      }
    },
    handleMouseMove({x, y}) {
      this.$store.dispatch('mouseMove', {x, y});
      switch(this.cursorState) {
        case 'drag_possible':
          this.$store.dispatch('flow/dragging');
          this.$store.commit('setCursorState', 'dragging')
          break;
        case 'dragging':
            this.$store.dispatch('flow/dragging')
          break;

        case 'drawing':
            this.$store.dispatch('flow/drawing')
          break;
      }
    },
    handleMouseUp ({x, y}) {
      this.$store.dispatch('mouseUp', {x, y});
      switch(this.cursorState) {
        case 'dragging':
            this.$store.dispatch('flow/dragEnd').then(({type, uid, on}) => {
              if (type === 'merger') {
                this.$nextTick(() => {
                  this.$store.dispatch('flow/updateAnchorCoords', {uid, on})
                })
              }
            })
          break;

        case 'drawing':
            this.$store.dispatch('flow/drawEnd')
          break;
      }
      this.$store.commit('setCursorState', 'default')
    },
    handleDblClick ({x, y, key}) {
      this.mouseStart = {x, y};
      this.eventKey = key;
      this.$store.dispatch('flow/createNode', {x, y, key})
    },
    handleScroll ({top, left}){
      this.$store.commit('setScroll',  { top, left });
    },
    handleMouseOver (node) {
      this.$store.commit('setMouseOver', (node ? node.uid : null))
    },
  },
};
</script>

<style lang="scss" scoped>

.participant {
  position: absolute;
}

.canvas {
  overflow: scroll;
  position: relative;

  &-outline {
    border: solid thin #ddd;
    pointer-events: none;
    position: absolute;
    border-radius: 0.25rem;
  }

  &-selector {
    border: solid 1px rgba(#ddd, 0.9);
    background: rgba(#fafafa, 0.4);
    position: fixed;
    pointer-events: none;
  }
}
</style>
